<script setup>
import { ref, computed, watch } from "vue";
import { Link, router } from "@inertiajs/vue3";
import BarChart from "@/Components/Charts/BarChart.vue";

const props = defineProps([
    "pubs",
    "facultyCount",
    "facultyInput",
    "selectedYear",
    "counts",
    "faculty",
]);

const pubs = ref(props.pubs);
const facultyCount = ref(props.facultyCount);
const facultyInput = ref(props.facultyInput ?? "");
const selectedYear = ref(props.selectedYear ?? "");
const countsTotal = ref(props.counts);
const facultyList = ref(props.faculty);

const pubData = pubs.value.data;
const pubValues = pubs.value;
const pubPaginate = pubValues.links;
const countData = countsTotal.value;
const facCount = facultyCount.value;
const facList = facultyList.value;

// order facList by desc last_name
facList.sort((a, b) => {
    return a.last_name.localeCompare(b.last_name);
});

// order countData by desc pub_year
const sortedCountData = computed(() => {
    return countsTotal.value.slice().sort((a, b) => b.pub_year - a.pub_year);
});

const pubFilter = ref([]);
const paginationLinks = ref([]);

// Function to handle faculty selection
function selectFaculty(facultyMember) {
    facultyInput.value =
        facultyMember.first_name + " " + facultyMember.last_name;
    searchFaculty(facultyInput.value);
}

// Function to perform the search
function searchFaculty(name) {
    let url = "";

    if (name) {
        url = `?faculty=${encodeURIComponent(name)}`;
    } else {
        url = `?faculty=`;
    }

    if (selectedYear.value) {
        url += `&year=${selectedYear.value}`;
    }

    router.get(url, {
        onSuccess: (dataSet) => {
            facultyInput.value = name;
            pubs.value = dataSet.pubs;
            facultyCount.value = dataSet.facultyCount;
            countsTotal.value = dataSet.counts;
        },
        onError: (error) => {
            console.error(error);
        },
    });
}

// Watch for changes in the year filter
watch(selectedYear, (newYear) => {
    searchFaculty(facultyInput.value);
});

// Compute data for the bar chart
const facultyTotal = computed(() => {
    const pubYearAll = countData.map((item) => String(item.pub_year));
    const pubCountAll = countData.map((item) => item.total);

    const pubYear = facCount.map((item) => item.pub_year);
    const pubCount = facCount.map((item) => item.total);

    if (!facultyInput.value) {
        return {
            labels: pubYearAll,
            datasets: [
                {
                    label: "Publications by Years",
                    data: pubCountAll,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else {
        const facultyName = facultyInput.value;
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label: `Publications by ${facultyName}`,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    }
});
</script>

<template>
    <div class="w-full max-w-screen-xl mx-auto p-6 bg-white">
        <div class="w-full">
            <BarChart
                v-if="facultyTotal && facultyTotal.datasets.length > 0"
                :data="facultyTotal"
            />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 pt-6">
            <!-- Faculty Selection Buttons -->
            <div class="col-span-1 md:col-span-1">
                <!-- <h1 class="items-center text-gray-600 text-md md:text-xl pb-4">
                    Select Author
                </h1>
                <div>
                    <button
                        v-for="facultyMember in facList"
                        :key="facultyMember.id"
                        @click="selectFaculty(facultyMember)"
                        class="w-full h-10 px-3 mt-4 text-sm md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                    >
                        {{ facultyMember.first_name }}
                        {{ facultyMember.last_name }}
                    </button>
                </div> -->
                <!-- Year Filter -->
                <div class="bg-white pt-4">
                    <h1
                        class="items-center text-gray-600 text-md md:text-xl pb-4"
                    >
                        Filter By Year
                    </h1>
                    <div class="relative">
                        <select
                            v-model="selectedYear"
                            class="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-cardinal-red focus:text-gray-900"
                        >
                            <option value="">All Years</option>
                            <option
                                v-for="(btn, index) in sortedCountData"
                                :key="index"
                                :value="btn.pub_year"
                            >
                                {{ btn.pub_year }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Publications Table -->
            <div
                class="max-h-[500px] overflow-y-scroll col-span-1 md:col-span-2 scrollable-div"
            >
                <table class="bg-white divide-y divide-gray-200 w-full">
                    <thead class="bg-gray-50">
                        <tr
                            class="text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                            <th class="text-xs">Faculty</th>
                            <th class="text-xs">Title</th>
                            <th class="text-xs">PMID</th>
                            <th class="text-xs pl-4">Year</th>
                        </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(x, index) in pubData" :key="index">
                            <td class="text-xs md:text-base pr-6 w-6">
                                {{ x.first_name }} {{ x.last_name }}
                            </td>
                            <td
                                class="text-xs md:max-w-sm md:text-base py-2 pr-6"
                            >
                                <p v-html="x.title" />
                            </td>
                            <td>
                                <a
                                    class="text-blue-600 underline hover:blue text-center text-xs md:text-base"
                                    :href="
                                        'https://pubmed.ncbi.nlm.nih.gov/' +
                                        x.pmid
                                    "
                                    target="_blank"
                                    >{{ x.pmid }}</a
                                >
                            </td>
                            <td class="text-xs md:text-base pl-4">
                                {{ x.pub_year }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in pubPaginate" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-xs md:text-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border text-xs md:text-lg rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>

<style>
.scrollable-div::-webkit-scrollbar {
    width: 14px; /* Adjust the width as needed */
}

.scrollable-div::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the scrollbar track */
}

.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #8c1515; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Adjust the radius as needed */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #e11f1f; /* Color of the scrollbar thumb on hover */
}
</style>
